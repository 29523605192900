export const hcpGTI: any = {
  hcpMainMenu: "Main Navigational Menu HCP Retina Brazil (PT)",
  hcpFooterMenu: "Footer Navigation Menu HCP Retina Brazil (PT)",
  hcpCarousel: "",
  hcpBannerHTML: "Banner - HTML - GTB - HCP - Brazil (PT)",
  hcpFooter: "Footer - HCP - Retina Brazil (PT)",
  hcpExitPopup: "Exit Popup HCP Brazil (PT)",
  hcpHCPSwitcher: "HCP Switcher Modal Pop Up - Retina Brazil (PT)",
  hcpTopNav: "Top Navigation Menu HCP Retina Brazil (PT)",
  hcpSiteLogo: "Site logos - Retina Brazil (PT)",
  socialBlock: "Footer Social Icons",
  hcpFootRef: "Reference - Genetic Testing Benefits - Retina Brazil (PT)",
  hcpInfographic: "Infographic - Genetic Testing Benefits - Retina Brazil (PT)",
  hcpGTMayPara: "Genetic Testing May give - Genetic Testing Benefits - Retina Brazil (PT)",
  hcpImpOfRetestPara: "The Importance Of Retesting - Genetic Testing Benefits - Retina Brazil (PT)",
  hcpGTCanRevealPara: "Genetic Testing Can Reveal - Genetic Testing Benefits - Retina Brazil (PT)",
  hcpResponsePara: "Response - The importance of genetic testing",
  hcpDidYouPara: "Did You Know - Genetic Testing Benefits - Retina Brazil (PT)",
  hcpCalloutSectionPara: "Callout Section - GTB - Retina Brazil (PT)",
  hcpTakeActionPara : "Take Action For Confirm - Genetic Testing Benefits - Retina Brazil (PT)",
  hcpNaviCalloutPara: "Footer Callout GTB - HTML HCP Retina Brazil (PT)",
  backToTopGTM: "back to top - genetic testing benefits - hcp",
  footerClassName: "genetic-testing-importance-footer"
}
