import styled from 'styled-components'
import { device } from '@retina-packages/retina-theme-bootstrap/packages/css/device'
import { MobileBanner } from '@retina-packages/retina-theme-bootstrap/packages/components/styles/styles';
import { HcptestResetpatientSection } from '@retina-packages/retina-theme-bootstrap/packages/css/CommonStyle';

export const ImportanceOfgeneticTesingWrapper = styled.div`
  & .hcp-genetic-benefit-wrapper-container {
    .blue-box-wrapper {
		  margin-bottom: 120px;
      max-width: 100%;

		  @media ${device.tablet} and ${device.ipadLandscape} {
			  padding: 0 5rem;
		  }
      @media ${device.desktopsignup} {
        width:1168px;
        padding: 36px 30px 32px;

		  }

		  @media ${device.laptopMedium} {
			  margin-bottom: 50px;
		  }

      .o-container{

        @media ${device.laptopMedium} {
          max-width: 718px!important;
          font-size: 2rem;
        line-height: 2.6rem;
        }
        @media ${device.mobileMedium}{
          padding: 0 1rem !important;
          padding-bottom: 20px;
        }

        .o-container{
          @media ${device.laptopMedium}{
            padding: 0;
          }
        }

      }
      .o-genes-wrap {
        margin: 0 40px;

        @media ${device.laptop} and ${device.midDesktopMax} {
          margin: 0;
        }

        @media ${device.desktopsignup} {
          width: 968px;
          padding: 36px 30px 32px;
          margin: 76px 100px 80px 127px;
        }

        @media ${device.laptopMedium} {
          margin: 0;
        }

        @media ${device.desktopsignup} {
          margin: 0;
        }

        .info-box-2 {
          padding: 40px 40px;
          margin: 0px 98px 120px;

          @media ${device.desktopsignup} and ${device.midDesktopMax}{
            margin: 0px !important;
          }

          @media ${device.tablet} and ${device.minideskend}{
            min-width: 590px;
          }

          @media ${device.desktopsignup} {
            width: 968px;
          }

          @media ${device.laptop} and ${device.midDesktopMax} {
            margin: 0;
          }

          @media ${device.laptopMedium} {
            max-width: 712px;
            padding-top: 20px;
            margin-bottom: 50px;
            box-shadow: 0 4px 6px #cecece;
            padding-left: 40px;
            padding-right: 40px;
            padding-bottom: 30px;
            justify-content: center;
          }

          @media ${device.mobileMedium}{
            padding: 40px 40px;
            margin: 0px 1px 50px;
            width: 100% !important;
            display: inline-block;
            max-width: unset !important;
          }

          @media ${device.laptopMedium} {
            padding: 20px 35px;
          }
        }
      }
	  }
  }
	.internal-banner__image {
    @media ${device.desktopsignup} {
      width: 100%;
      height: 341px;
      object-fit: contain;
    }

		img {
			object-fit: cover;
			width: 100%;
			object-position: center center;
			height: 100%;
			display: block;

			@media ${device.laptopPros} {
				height: 100%;
    		object-position: 93% 0%;
			}

			@media ${device.ipadLandscape} {
				height: 100%;
    		object-position: 90% 0%;
			}
		}
	}

	.internal-banner__contant {
    .o-container{
      h1{
				width: 706px;
				height: 192px;
				font-family: 'Gotham', Arial, Helvetica, sans-serif;
				font-size: 53px;
				font-weight: bold;
				font-stretch: normal;
				font-style: normal;
				line-height: 1.21;
				letter-spacing: normal;
				text-align: left;
				color: #fff;

				@media ${device.desktopbig} {
					padding-left: 0px !important;
					margin-left: 0px !important;
				}

				@media ${device.ipadLandscapemin} and ${device.minimumDeskEnd} {
					font-size: 52px !important;
					line-height: 1.16 !important;
					width: 716px;
				}

				@media ${device.smalldeskstrt} and ${device.smalldeskmid} {
					width: 67%;
				}

				@media ${device.ipadLandscapemin} and ${device.laptopair2max} {
					width: 70%;
				}

				@media ${device.ipadair} and ${device.laptopMedium} {
					width: 60%;
				}

				@media ${device.tablet} and ${device.laptopMedium}{
					margin-top: 20px !important;
				}
      }
    }
		.internal-banner__large-title,
		h1 {
			h1 {
				width: 706px;
				height: 192px;
				font-size: 5.3rem;
				line-height: 5.9rem;
				color: #fff;
				text-transform: uppercase;
				font-weight: bold;
        margin-top:25px;

        ${device.mobileMedium}{
          margin-top: 40px !important;
        }
				@media ${device.desktopbig} {
          padding-left: 0px !important;
          margin-left: 0px !important;
				}

				@media ${device.laptopMedium} {
					text-align: center;
					width: 100%;
					margin: auto;
					max-width: 100%;
					font-size: 3.2rem;
					line-height: 3.8rem;

				}
        @media (min-width: 992px) and (max-width: 1024px){
          padding-left: 50px !important;
        }
			}
		}
	}

	.fullwidth-listing {
		padding: 80px 0 120px;

    @media ${device.mobileMedium} {
      margin-top: 65px;
    }

		@media ${device.laptopMedium} {
			padding: 27px 0 48px;
			padding-bottom: 10px !important;
      margin-bottom: -25px;
		}

	@media ${device.ipadLandscapemin} and ${device.laptopair2max}{
		padding-bottom: 15px !important;


            }
		.o-container {
			.o-inner {
				padding: 0 130px;
        font-size: 2rem;
        line-height: 2.4rem;

        @media ${device.mobileMedium} {
					margin: 0 !important;
				}

				@media ${device.laptopMedium} {
					padding: 0;
					margin: 0 80px;
				}

				@media ${device.desktopsignup} {
					width: 100%;
          margin-left: 10px;
				}


				.o-header {
					color: #000000;
					font-weight: 700;
					text-align: center;
					font-size: 4.2rem;
					line-height: 5rem;

          @media ${device.mobileMedium}{

            font-size: 2rem;
            line-height: 2.4rem;
          }

					@media ${device.desktopsignup} {
					width:860px;
					height:100px;
					font-family: 'Gotham', Arial, Helvetica, sans-serif;
					margin:-10px;
					padding-left:-20px;
					font-size: 42px;
					font-weight: bold;
					font-stretch: normal;
					font-style: normal;
					line-height: 1.19;
					letter-spacing: normal;
					text-align: center;
					color: #000;
					}

					@media ${device.laptopMedium} {
						font-size: 2.5rem;
						line-height: 3rem;
						font-weight: 700;
    					text-align: center;
					}

					strong {
						font-weight: 700;
					}

					span {
						color: #8000BE;
					}
				}

				.full-listing-block {
					display: inline-block;
					padding: 0;
					list-style: none;
					margin: 40px 0 0;
					width:968px;

          @media ${device.mobileMedium} {
            padding: 0 0 10px 33px !important;
          }


					@media ${device.laptopMedium} {
						margin: 20px 0 0;
					}

					li {
						color: #000000;
						padding: 12px 0;
						line-height: 1.19 !important;
						font-family: "Gotham", Arial, Helvetica, sans-serif;
						font-weight: 500;
						font-size: 2.7rem;
						text-indent: -45px;
						position: relative;
						display:flex;
						display:inline-block !important;

						@media ${device.laptopMedium} {
							line-height: 2.4rem !important;
							position: relative;
							padding: 8px 0 8px 40px;
							color: #000000;
							font-size: 2rem;
							text-indent: unset;
							padding-left: 5px !important;
						}
            @media ${device.ipadLandscapemin} and ${device.minideskend}{
              line-height: 3.8rem !important;
            }
						&:before {
							content: "";
							position: absolute;
							left: -42px;
							top: 7px;
							width: 38px;
							height: 38px;
							text-align: center;
							background-size: 95%;
							background-position: center;
							background-repeat: no-repeat;

						  }
							sup {
								font-size: 2rem !important;
								top: -1rem !important;
								line-height: 0;
								vertical-align: baseline;
								position: relative;

                @media ${device.laptopMedium} {
                  font-size: 1rem !important;
                }

							}
						span {
							display: inline-block;
							background-color: #8000BE;
							color: #ffffff;
							border-radius: 50%;
							text-align: center;
							width: 38px;
							height: 38px;
							line-height: 38px;
							font-size: 2.2rem;
							margin: 0 4px 0 0;
							text-indent: 0;

							@media ${device.laptopMedium} {
								width: 32px;
								height: 32px;
								line-height: 32px;
								font-size: 2rem;
								position: absolute;
								left: 0;
								margin-right: 0;
								margin-top: -3px;
								text-indent: unset;
							}
						}

						a {
							color: #000000;
							cursor: default;
							text-decoration: none;
						}
					}
				}
			}
		}
	}


	.generic-testing-benefits {
		padding: 0px 140px;
		position: relative;

		@media ${device.laptopMedium} {
			padding: 0;
		}

		&:before {
			content: "";
			background-color: #00FFD9;
			position: absolute;
			top: 120px;
			left: 0;
			height: calc(100% - 305px);
			width: 100%;
		}

		.importance-rest--block {
			padding: 0 0 50px;
			background-color: #F7F7F7;
			box-shadow: 0px 5px 15px 0px rgb(0 0 0 / 10%);
    	position: relative;

			@media ${device.laptopPros} {
				margin: 0 28px;
				padding: 0 0 30px;
			}

			&:before {
				content: "";
				background: linear-gradient(90deg, #8000be 0%, #594dc6 35%, #17d0d4 64%, #00ffd9 100%);
				position: absolute;
				top: 0;
				width: 100%;
				height: 22px;
				left: 0;
				right: 0;

				@media ${device.laptopMedium} {
					height: 12px;
				}
			}

			.faq-block {
				padding-top: 62px;
				position: relative;
				margin-bottom: 81px;
				margin-top: 70px !important;

        @media ${device.mobileMedium} {
					margin-bottom: 30px;
				}

				@media ${device.laptopMedium} {
					padding-top: 42px;
				}

				@media ${device.desktopsignup} and ${device.midDesktopMax}{
					width: 100%;
				}

				}
				h2 {
					color: #000000;
					text-align: center;
					padding-bottom: 40px;
					font-size: 4.2rem;
					font-weight: 600;
					line-height: 5rem;
					margin-top:50px;


					@media ${device.laptopPros} {
						width: 100%;
						display: block;
					}

					@media ${device.laptopMedium} {
						padding-bottom: 20px;
						font-size: 2.5rem;
    					line-height: 3rem;
						text-align: center;
   					 	margin-left: 10px;
					}
					@media ${device.ipadLandscapemin} and ${device.laptopair2max}{
						margin: 10px !important;
						margin-top: 0px;
						}
            @media ${device.laptopair2max}and ${device.smallDesktop} {
              padding: 0 20px;
              padding-bottom: 20px;
              margin: 10px !important;
            }
						@media ${device.smalldeskstrt} and ${device.smallDesktop}{
							margin: 10px !important;

						}
					@media ${device.desktopsignup}  and ${device.midDesktopMax} {

					}
				}

				.faq-question {

					margin: 0 80px 51px 90px;
					position: relative;

					@media ${device.ipadLandscapemin} and ${device.laptopair2max}{
						margin: 0 65px 50px 60px;
						}
					@media ${device.desktopsignup} and ${device.midDesktopMax} {
						margin: 0 35px 51px 100px;
					position: relative;
					}

					@media ${device.laptopMedium} {
						margin: 0 auto 20px;
						text-align: center;
					}


					img {
						position: absolute;
						top: 5px;
						left: 0;

						@media ${device.laptopMedium} {
							width: 50px;
							position: unset;
							left: unset;
							top: unset;
							margin-bottom: 15px;
							height: auto;
						}
					}

					.faq-answer {
						padding-left: 110px;


            @media ${device.mobileMedium} {
              padding: 0 10px !important;
            }

					@media ${device.desktopsignup}  and ${device.midDesktopMax} {
						padding-right: 35px;
					}
						@media ${device.laptopMedium} {
							padding-left: unset;
							text-align: center;
							padding: 0 55px;
						}


						.o-paragraph {
							font-weight: normal;
							line-height: 1.4;
							color: #000000;
							font-size: 2.2rem;

							@media ${device.laptopMedium} {
								font-size: 1.8rem;
								line-height: 2.6rem;
							}



							strong {
								font-weight: 700;
							}

							sup {
								font-size: 60%;
								top: -0.5em;
								line-height: 0;
								vertical-align: baseline;
								position: relative;
							}

							}

						}
					}

					&:nth-child(3) {
						img {
							transform: rotate(15deg);
							width: 68px;
							height: 97px;
						}
					}

					&:last-child {
						img {
							width: 70px;
							height: 85px;

							@media ${device.laptopMedium} {
								width: 50px;
								height: auto;
							}
						}
					}
				}
			}
		}
	}

	.case-studies--block {
		padding: 88px 0 0;

		@media ${device.laptopMedium} {
			padding: 10px 0 0;
		}

		.o-columns--row {
			justify-content: center;
			display: flex;
			flex-wrap: wrap;
			flex-direction: row;
			align-items: stretch;
    	margin: 30px 0 0;

			@media ${device.laptopMedium} {
				display: block;
			}

			span, .item {
				max-width: 468px;
				margin: 0 20px;
				padding: 30px 25px 100px;
				box-sizing: border-box;
				position: relative;
				text-align: center;
        width: 468px;

				background-color: #8000BE;
				display: block;

				@media ${device.laptopMedium} {
					max-width: 340px;
					margin: 20px auto;
					width: 100%;
					padding: 15px 20px !important;
				}

				@media (min-width: 1366px){
					max-width: 468px;
				}
				@media ${device.desktopsignup} and ${device.midDesktopMax} {
					max-width: 428px;
				}

				.o-columns--two {
					.media--image {
						img {
							display: block;
							margin: 0 auto;

							@media ${device.laptopMedium} {
								margin: 10px auto;
								width: 50px;
							}
						}
					}

					img {
						display: none;
					}

					.o-paragraph {
						margin: 30px auto;
						width: 100%;
						padding: 0;
						text-align: center;
						color: #fff;
						font-size: 2.2rem;
						font-weight: 500;
						line-height: 3.2rem;

						@media ${device.laptopMedium} {
							line-height: 22px;
							width: 100%;
							font-size: 1.8rem;
							margin: 16px 0 32px;
						}
					}

					.o-button {
						position: absolute;
						bottom: 40px;
						left: 0;
						right: 0;
						margin: 0 auto;
						display: inline-block;
           	width: 372px;
            height: 54px;
						padding: 10px;
						color: #8000BE;
						background-color: #fff;
						font-size: 2.2rem;
						font-weight: 500;
						line-height: 3.2rem;
						text-decoration: none;
						z-index: 1;
						vertical-align: top;
						overflow: hidden;
						padding: 10px 25px;
						border: none;
						letter-spacing: 0;
						border-radius: 6px;
						background: #fff;
						transition: all 0.3s ease;
						cursor: pointer;

						@media ${device.laptopMedium} {
							margin: 0 auto 10px;
							position: unset;
							font-size: 1.8rem;
							display: block;
							max-width: 245px;
							width: 100%;
						}

						&:hover {
							color: #fff;
							background-color: #7323CA;
						}

						&:after {
							content: '';
							display: block;
							width: 16px;
							height: 14px;
							float: right;
							position: relative;
							top: 10px;
							left: -20px;

							@media ${device.laptopMedium} {
								left: 0;
							}
						}
					}
				}
			}
		}
	}

	.genetic-cause-wrapper {
		.o-inner {
			padding: 0;

			.genetic-cause {
				width: 80%;
				padding: 120px 50px;
				margin: 0 auto;

        @media ${device.ipadLandscapemin} and ${device.smallDesktop}{
          padding: 150px 0 120px;
        }


				@media ${device.laptopMedium} {
					width: 100%;
					margin: 20px 0 30px;
					padding: 24px 0;
					flex-direction: column;
				}
				@media ${device.ipadLandscapemin}{
					padding: 110px 0px;
				}

				.genetic-cause__right-text-secion {
					padding-left: 60px;

					@media ${device.laptopMedium} {
						padding-left: 0;
					}
					p{
						boder: 10px red solid;

						@media ${device.laptopMedium} {
						  text-align: center;
   						font-size: 2rem;
    					line-height: 2.6rem;
   						margin-top: 0;
						}

            @media ${device.ipadLandscapemin} and ${device.minideskend}{
              font-size: 2.7rem;
              line-height: 3.4rem;
              font-family: 'Gotham', Arial, Helvetica, sans-serif;
              margin-bottom: 0;
            }
					}
				}
			}
		}
	}

	.image-points-section {
		padding-bottom: 88px;

		@media ${device.laptopMedium} {
			padding-bottom: 40px;
      padding-top:20px;
		}


		h2 {
			width:962px;
			font-family: 'Gotham', Arial, Helvetica, sans-serif;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.19;
      letter-spacing: normal;
      text-align: center;
			margin: auto;
			font-size: 4.2rem;
      margin-bottom: -20px;
      margin-top:-40px;
			color: #000000;
			text-align: center;

			@media ${device.laptopMedium} {
				padding: 0 30px;
				width: 100%;
				display: block;
    		margin: auto;
				text-align: center;
				font-size: 2.5rem;
				line-height: 3rem;
        margin-bottom: 0px;

			}
      @media ${device.mobileMedium}{
        margin-bottom: 10px;
      }
		}

		.listing-btm-section {
			padding: 50px 10px 10px;
			display: flex;
			flex-wrap: wrap;
			align-items: baseline;
      max-width: 1568px;
      width: 100%;

			@media ${device.laptopMedium} {
				display: block;
				padding: 20px 0 0;
			}

			.media--image {
				width: 47.4%;
				margin: 0 auto;

				@media ${device.desktopStart} {
					width: 48%;
				}

				@media ${device.laptopL} {
					width: 46%;
				}

				@media ${device.extraLargeDesktop} {
					width: 46.5%;
					max-width: unset;
				}

				@media ${device.laptopMedium} {
					width: 100%;
					margin: 0 auto 17px;
				}
        @media ${device.desktopsignup} {
          height: 414px;
          object-fit: contain;
        }

				.gatsby-image-wrapper {
					>div {

						@media ${device.desktopStart} {
							max-width: 805px !important;
						}
					}

					img {
            height: 386px;
            bottom: 0;
            height: 100%;
            left: 0;
            padding: 0 !important;
            position: absolute;
            right: 0;
            top: 0px;
            width: 100%;


						@media ${device.desktopStart} {
							max-width: 805px;
							width: 805px;
						}

						@media ${device.extraLargeDesktop} {
							max-width: 884px;
							width: 884px;
						}

            @media ${device.desktopsignup} {
              height: 414px;
            }

					}
				}
			}

			.right-listing-points {
				margin: initial;
				align-items: flex-end;
				justify-content: end;
				padding-left: 48px;
				width: 51.6%;
				padding-right: calc((100vw - 1168px) / 2);
				display: table;

				@media ${device.laptopMedium} {
					margin-top: 30px;
					padding-left: 28px;
					padding-right: 28px;
					width: 100%;
					margin: 30px 0 0 0;
				}

        @media ${device.desktopsignup} {
          height: 414px;
          object-fit: contain;

        }
        @media ${device.desktopsignup}{
          padding-left: 40px;
          padding-right: 75px;
        }
        @media ${device.largeS}{
          padding-left: 40px;
          padding-right: 100px;
        }
        @media ${device.largeDesktop}{
          padding-left: 0px;
          padding-right: 200px;
        }
        @media ${device.extraLargeDesktop}{
          padding-left: 0px;
          padding-right: 80px;
        }


				.o-paragraph {
					font-weight: 500;
					font-size: 22px;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.45;
					color: #000000;

					@media ${device.laptopMedium} {
						margin-top: 0px;
						font-size: 1.8rem;
						line-height: 2.6rem;
					}
					@media ${device.desktopsignup} {
						margin-top: 10px;
						font-size: 1.8rem;
						line-height: 2.6rem;
					}
					@media (min-width: 1366px){
						font-family: 'Gotham', Arial, Helvetica, sans-serif;
  					font-size: 22px;
  					font-weight: 500;
  					font-stretch: normal;
  					font-style: normal;
  					line-height: 1.45;
  					letter-spacing: normal;
            text-align: left;
            color: #000;
					}


				}

				.listing-section {
					margin-top: 20px;
					margin: auto;
    			display: table;
					padding: 0;

					@media ${device.laptopMedium} {
						margin-top: 15px;
					}
          @media ${device.desktopStart}{
            margin-left: -14px;
          }

					li {
						padding: 0 0 24px 25px;
						font-family: 'Gotham-Book', Arial, Helvetica, sans-serif;
						display: inline-block;
    				position: relative;
						font-size: 2.2rem;
						font-weight: normal;
						font-style: normal;
						font-stretch: normal;
						line-height: 1.45;
						letter-spacing:normal;
						color: #000000;
						text-align: left;

						@media (min-width: 1366px){
							margin: 0 0 0 16px;
						}

						@media ${device.laptopMedium} {
							padding: 0px 0 15px 24px;
							margin-top: 0px;
							font-size: 1.8rem;
    					line-height: 2.2rem;
						}

						&:before {
							content: "";
							position: absolute;
							background-color: #8000BE;
							width: 8px;
							height: 8px;
							top: 12px;
							margin: auto;
							left: 0;
							@media ${device.laptopMedium} {
							left: 1px;
							}
						}

						sup {
							font-size: 60%;
							line-height: 0;
							vertical-align: baseline;
							position: relative;
							top: -0.5em;
						}
					}
				}
			}
		}
	}



	.generic-testing-benefits-cover-bg.o-section--cover-bg {
		margin-bottom:56px;

		@media ${device.desktopsignup}{
			padding: 0 100px;
		}

		.o-container {
			margin-left: auto;
			margin-right: auto;

      @media ${device.laptopMedium} {
       margin-top: 30px;
      }

			@media (min-width: 1280px){
			max-width: 1188px;
			width: 100%;
			margin-left: auto;
			margin-right: auto;
			margin-bottom: 120px;

			}

			h2.o-header {
				font-size: 4.2rem;
				padding: 60px 0px 40px;
        margin-top: -90px;
				font-family: 'Gotham', Arial, Helvetica, sans-serif;
				font-weight: bold;
				font-stretch: normal;
				font-style: normal;
				line-height: 1.19;
				letter-spacing: normal;
				text-align: center;
				color: #000;

			}

			.o-columns {
				margin: 0px auto 140px;
				width: calc(100% + 30px);
				font-size: 2.2rem;
				font-weight: 500;
				line-height: 3.2rem;
				color: rgb(0, 0, 0);
				text-align: center;
			}
      .primary-text--card{

        @media ${device.desktopsignup} {
          width: 100%;
          padding: 60px 28px 55px;
          box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.16);
          background-color: #300064;


					@media ${device.desktopsignup} and ${device.midDesktopMax}{
						margin-bottom: 15px;
					}

        }
.o-paragraph{
	min-width: 100%;
  font-family: 'Gotham', Arial, Helvetica, sans-serif;
  font-size: 22px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: normal;
  text-align: center;
  color: #00ffd9;

  @media ${device.laptopMedium}{
	padding 0px 15px;
  }
}
      }
		}
	}

	.emr-footer-block {
		@media ${device.desktopsignup} and ${device.midDesktopMax} {
			margin-left:-12px;
		}
		@media (min-width: 1366px){
			margin-right:-22px;
		}

		.o-container{
			padding-left: -25px;
		}
		.footer-block--row{
		padding:0px !important;

    @media ${device.laptopPros}{
      max-width: 40%;
      display: block;
      height: auto;
      justify-content: center;
    }

    @media ${device.laptopMedium}{
      max-width: 532px;
      margin-left: 15px;
      width: 100%;
      padding:0;
      margin:0;
    }

		}
		.footer-block {
			&.footer-block-bg {
				background: transparent;

        @media ${device.laptopPros}{
          min-width: 100%;
          height: auto;

        }

			}
			.footer-inner-block {

	.o-button{
		&:hover {
			color: #fff;
			background-color: #7323CA;
		}
}
				padding: 32px 32px 128px;
				min-width: 468px;
        width: 100%;
				height: auto;


				@media (min-width: 992px) and (max-width: 1365px) {
					min-width: unset;
					height: auto;
				}

				@media ${device.laptopMedium} {
					margin-top: 0;
					min-width: 100%;
					border-radius: 10px;
					height: auto;
					padding: 30px 16px 16px;
				}

&:nth-child(1){
	@media ${device.desktopsignup} {
		width: 468px;
		height: 496px;
	}

	@media ${device.desktopsignup} and ${device.midDesktopMax} {
		width:468px;
		height: 496px;
		margin-left:-94px;

	}
}
				&:nth-child(2) {
					@media ${device.laptopMedium} {
						margin-top: 15px;
					}
					@media ${device.desktopsignup} {
						width: 468px;
						height: 496px;
					}
					@media ${device.desktopsignup} and ${device.midDesktopMax} {
						width:468px;
						height: 496px;

					}

				}
				h3{
					p{
						width: 420px;
  height: 70px;
  margin: 24px 24px 16px -10px;
  font-family: 'Gotham', Arial, Helvetica, sans-serif;
  font-size: 27px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.41;
  letter-spacing: normal;
  text-align: center;
  color: #8000be;
					}
				}

		}
	}
`;

export const GTIMobileBanner = styled(MobileBanner)`
.internal-banner {
  .internal-banner__image {
    @media ${device.mobileMedium} {
      height: 310px;
    }
  }
}`;

export const HCPGTIBanner = styled(HcptestResetpatientSection)`
.imp-gen-testing {
  .importance-genetic {
    .internal-banner {
      .internal-banner__image {
        @media ${device.mobileMedium} {
          height: 330px;
        }
      }
    }
  }
}

.clinical-trials-banner{
  .internal-banner{
    .gatsby-image-wrapper-constrained{
      width:100%;
      @media ${device.mobileMedium}{
        height: 310px !important;
      }
      @media ${device.laptopMedium}{
        height: 470px;
        object-position: 90% 0%;
      }
      @media ${device.desktopsignup}{
        margin-top: -2px;
      }
      @media ${device.largeDesktop}{
        height: 380px;
      }
      @media ${device.extraLargeDesktop}{
        height: 410px;
      }
    }
    &:after{
      @media ${device.largeDesktop}{
       top: 94%;
      }
      @media ${device.extraLargeDesktop}{
        top: calc(100% + 36px);
      }
    }
    .internal-banner__contant{
      .o-container{
        padding:0 3rem;

        @media ${device.tablet}{
          padding-top:0;
        }
        @media ${device.ipadLandscapemin}{
          padding: 0 5rem;
        }
        @media ${device.desktopStart}{
          padding: 0 2rem;
        }

        h1{
          @media ${device.ipadLandscapemin} and ${device.desktopmenu} {
            margin: 15px 0;
            width: 100% !important;
          }
          @media ${device.extraLargeDesktop}{
            margin-top: 5rem;
          }
        }
      }
    }
    .internal-banner__image{
      @media ${device.laptopMedium}{
        height: 470px;
      }
      .desktop-image{
        height: 100%;
          .media--image{
            height: 100%;
          }
        img{
          display: block;
          object-fit: cover;
          width: 100%;
          height: 100%;
          object-position: 90% 0%;

          @media ${device.laptopPros}{
            display: none;
          }
          @media ${device.ipadLandscapemin} {
            width: 100%;
          }
        }
      }
      .mobile-image{
        .media--image{
          height: 100%;
        }
        img{
          display: none;
          @media ${device.laptopPros}{
            display: block;
            object-fit: cover;
            width: 100%;
            height: 100%;
            object-position: center !important;
          }
        }
      }
    }
  }
}

.footer{
  .o-backtotop-btn{
    @media ${device.laptopMedium}{
      background-color: transparent;
    }
  }
}
`;

export const SLTestResetpatientSection = styled(HcptestResetpatientSection)`
.hcp-genetic-benefit-wrapper-container {
  .emr-footer-block {
    .footer-block {
      margin-bottom: 0px;
    }
  }
}
`
